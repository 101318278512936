import { Types } from './actions';

export const initState = {
  payment: [],
  license: [],
  requestingPayment: false,
  requestingLicense: false,
  requestingAddPayment: false,
  requestingBindPayment: false,
  requestingBindCard: false,
  requestingDeletePayment: false,
  requestingOrderPlan: false,
  requestingCheckCoupon: false,
  requestingCheckCouponDiscount: false,
};

export const mutations = {
  [Types.FETCH_USER_PAYMENT_FETCHING](state) {
    state.requestingPayment = true;
  },
  [Types.FETCH_USER_PAYMENT_SUCCESS](state, data) {
    state.requestingPayment = false;
    state.payment = data.value;
  },
  [Types.FETCH_USER_PAYMENT_FAILED](state) {
    state.requestingPayment = false;
  },

  [Types.FETCH_USER_LICENSE_FETCHING](state) {
    state.requestingLicense = true;
  },
  [Types.FETCH_USER_LICENSE_SUCCESS](state, data) {
    state.requestingLicense = false;
    state.license = data.value;
  },
  [Types.FETCH_USER_LICENSE_FAILED](state) {
    state.requestingLicense = false;
  },

  [Types.ADD_USER_PAYMENT_FETCHING](state) {
    state.requestingAddPayment = true;
  },
  [Types.ADD_USER_PAYMENT_SUCCESS](state) {
    state.requestingAddPayment = false;
  },
  [Types.ADD_USER_PAYMENT_FAILED](state) {
    state.requestingAddPayment = false;
  },

  [Types.BIND_USER_PAYMENT_FETCHING](state) {
    state.requestingBindPayment = true;
  },
  [Types.BIND_USER_PAYMENT_SUCCESS](state) {
    state.requestingBindPayment = false;
  },
  [Types.BIND_USER_PAYMENT_FAILED](state) {
    state.requestingBindPayment = false;
  },

  [Types.BIND_USER_CARD_FETCHING](state) {
    state.requestingBindCard = true;
  },
  [Types.BIND_USER_CARD_SUCCESS](state) {
    state.requestingBindCard = false;
  },
  [Types.BIND_USER_CARD_FAILED](state) {
    state.requestingBindCard = false;
  },

  [Types.DELETE_USER_PAYMENT_FETCHING](state) {
    state.requestingDeletePayment = true;
  },
  [Types.DELETE_USER_PAYMENT_SUCCESS](state) {
    state.requestingDeletePayment = false;
  },
  [Types.DELETE_USER_PAYMENT_FAILED](state) {
    state.requestingDeletePayment = false;
  },

  [Types.ORDER_PLAN_FETCHING](state) {
    state.requestingOrderPlan = true;
  },
  [Types.ORDER_PLAN_SUCCESS](state) {
    state.requestingOrderPlan = false;
  },
  [Types.ORDER_PLAN_FAILED](state) {
    state.requestingOrderPlan = false;
  },

  [Types.CHECK_COUPON_FETCHING](state) {
    state.requestingCheckCoupon = true;
  },
  [Types.CHECK_COUPON_SUCCESS](state) {
    state.requestingCheckCoupon = false;
  },
  [Types.CHECK_COUPON_FAILED](state) {
    state.requestingCheckCoupon = false;
  },

  [Types.CHECK_COUPON_DISCOUNT_FETCHING](state) {
    state.requestingCheckCouponDiscount = true;
  },
  [Types.CHECK_COUPON_DISCOUNT_SUCCESS](state) {
    state.requestingCheckCouponDiscount = false;
  },
  [Types.CHECK_COUPON_DISCOUNT_FAILED](state) {
    state.requestingCheckCouponDiscount = false;
  },
};
