import jwt from 'jsonwebtoken';
import { getItem } from '@/utils/storageHelper';

export const getTokenFromLocalStorage = () => getItem('token');

export const setTokenToLocalStorage = (token) => localStorage.setItem('token', token);

export const checkAuth = (token) => {
  const decoded = jwt.decode(token);
  if (decoded === null) {
    return false;
  }
  if (decoded.expire_at && decoded.expire_at * 1000 < Date.now()) {
    return false;
  }
  return true;
};
