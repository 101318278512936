import RouteMeta from './RouteMeta';

export default {
  path: '/user',
  component: () => import(/* webpackChunkName: "create" */ '@/pages/Create.vue'),
  props: true,
  meta: new RouteMeta({
    auth: true,
  }),
  children: [
    {
      path: '',
      name: 'user',
      component: () => import(/* webpackChunkName: "user" */ '@/pages/Membership.vue'),
      props: true,
      meta: new RouteMeta({
        auth: true,
      }),
    },
    {
      path: 'create',
      name: 'create-user',
      component: () => import(/* webpackChunkName: "create-user" */ '@/components/UserForm.vue'),
      props: true,
      meta: new RouteMeta({
        auth: true,
      }),
    },
  ],
};
