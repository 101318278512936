import { Types } from './actions';

export const initState = {
  contracts: [],
  currentContract: null,
  requestingContracts: false,
  requestingCreateContract: false,
  requestingSendContractToOpposite: false,
  requestingVerifyContract: false,
};

export const mutations = {
  [Types.FETCH_CONTRACTS_FETCHING](state) {
    state.requestingContracts = true;
  },
  [Types.FETCH_CONTRACTS_SUCCESS](state, data) {
    state.requestingContracts = false;
    state.contracts = data.value.content;
  },
  [Types.FETCH_CONTRACTS_FAILED](state) {
    state.requestingContracts = false;
  },

  [Types.CREATE_CONTRACT_FETCHING](state) {
    state.requestingCreateContract = true;
  },
  [Types.CREATE_CONTRACT_SUCCESS](state, data) {
    state.requestingCreateContract = false;
    state.currentContract = data.value;
  },
  [Types.CREATE_CONTRACT_FAILED](state) {
    state.requestingCreateContract = false;
  },

  [Types.SEND_CONTRACT_TO_OPPOSITE_FETCHING](state) {
    state.requestingSendContractToOpposite = true;
  },
  [Types.SEND_CONTRACT_TO_OPPOSITE_SUCCESS](state) {
    state.requestingSendContractToOpposite = false;
  },
  [Types.SEND_CONTRACT_TO_OPPOSITE_FAILED](state) {
    state.requestingSendContractToOpposite = false;
  },

  [Types.VERIFY_CONTRACT_FETCHING](state) {
    state.requestingVerifyContract = true;
  },
  [Types.VERIFY_CONTRACT_SUCCESS](state) {
    state.requestingVerifyContract = false;
  },
  [Types.VERIFY_CONTRACT_FAILED](state) {
    state.requestingVerifyContract = false;
  },
};
