/* eslint-disable no-unused-vars */
import { checkAuth } from '@/utils/auth';
import store from '@/store';
import { Login, Home, User } from '../routes';

export default async function (to, from, next) {
  const { token } = store.state.auth;
  const isTokenValid = checkAuth(token);
  if (to && to.meta && to.meta.auth === true) {
    if (!store.state.auth.isAuthenticated) {
      return next(Login);
    }

    if (isTokenValid) {
      store.dispatch('FETCH_USER_PROFILE', {
        goCreateUser: () => {
          next({
            name: 'create-user',
            query: {
              auth: from.name ? from.query.auth : to.query.auth,
            },
          });
        },
        from,
        to,
      });
      return next();
    }

    store.dispatch('REQUEST_LOGOUT', { isTokenExpired: true });
    return next(Login);
  }

  if (
    to &&
    [Login.name].indexOf(to.name) !== -1 &&
    isTokenValid &&
    store.state.auth.isAuthenticated
  ) {
    next({
      name: 'home',
      query: {
        auth: from.name ? from.query.auth : to.query.auth,
      },
    });
  }

  return next();
}
