import RouteMeta from './RouteMeta';

export default {
  path: '/menu',
  name: 'menu',
  component: () => import(/* webpackChunkName: "menu" */ '@/pages/ContractMenu.vue'),
  props: true,
  meta: new RouteMeta({
    auth: true,
  }),
};
