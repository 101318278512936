import { getTokenFromLocalStorage, setTokenToLocalStorage, checkAuth } from '@/utils/auth';
import { clear } from '@/utils/storageHelper';
import AuthToken from '@/utils/AuthToken';
import { Types } from './actions';

export const initState = {
  token: null,
  profile: null,
  decoded: {},
  isAuthenticated: false,
  partnerAuthData: null,
  profileRequesting: false,
  requesting: false,
};

const token = getTokenFromLocalStorage();
if (token) {
  if (checkAuth(token)) {
    initState.isAuthenticated = true;
    const { token: authToken, decoded } = new AuthToken({ token }).deserialize();
    initState.token = authToken;
    initState.decoded = decoded;
  } else {
    clear();
  }
}

export const mutations = {
  [Types.LOGOUT](state, payload) {
    state.isAuthenticated = false;
    state.token = null;
    clear();
    if (payload.isTokenExpired) {
      const duration = 3000;
      console.log('連線逾時，請重新登入');
      setTimeout(() => {
        window.location.reload();
      }, duration);
    } else {
      window.location.reload();
    }
  },
  [Types.USER_LOGIN_FETCHING](state) {
    state.requesting = true;
  },
  [Types.USER_LOGIN_SUCCESS](state, data) {
    state.requesting = false;
    state.profile = data.value;
  },
  [Types.USER_LOGIN_FAILED](state) {
    state.isAuthenticated = false;
    state.requesting = false;
    clear();
  },
  [Types.VERIFY_MOBILE_FETCHING](state) {
    state.requesting = true;
  },
  [Types.VERIFY_MOBILE_SUCCESS](state, data) {
    const { token: authToken, decoded } = new AuthToken({ token: data.value.token }).deserialize();
    state.requesting = false;
    state.token = authToken;
    state.decoded = decoded;
    state.isAuthenticated = true;
    setTokenToLocalStorage(authToken);
  },
  [Types.VERIFY_MOBILE_FAILED](state) {
    state.isAuthenticated = false;
    state.requesting = false;
    clear();
  },
  [Types.FETCH_USER_PROFILE_FETCHING](state) {
    state.profileRequesting = true;
  },
  [Types.FETCH_USER_PROFILE_SUCCESS](state, data) {
    state.profileRequesting = false;
    state.profile = data.value;
  },
  [Types.FETCH_USER_PROFILE_FAILED](state, data) {
    state.profileRequesting = false;
    // response 403, auto logout
    if (data.error.response.status === 403) {
      state.isAuthenticated = false;
      state.token = null;
      clear();
      window.location.reload();
    }
  },
  [Types.UPDATE_USER_FETCHING](state) {
    state.requesting = true;
  },
  [Types.UPDATE_USER_SUCCESS](state) {
    state.requesting = false;
  },
  [Types.UPDATE_USER_FAILED](state) {
    state.requesting = false;
  },
  [Types.PARTNER_AUTH_FETCHING](state) {
    state.requesting = true;
  },
  [Types.PARTNER_AUTH_SUCCESS](state, data) {
    state.requesting = false;
    state.partnerAuthData = data.value;
  },
  [Types.PARTNER_AUTH_FAILED](state) {
    state.requesting = false;
  },
};
