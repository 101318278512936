import RouteMeta from './RouteMeta';

export default {
  path: '/insurance',
  name: 'insurance',
  component: () => import(/* webpackChunkName: "insurance" */ '@/pages/InsuraceMenu.vue'),
  props: true,
  meta: new RouteMeta({
    auth: true,
  }),
};
