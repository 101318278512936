import jwtService from 'jsonwebtoken';

class AuthToken {
  constructor({ token }) {
    this.token = token;
  }

  deserialize() {
    const decoded = jwtService.decode(this.token);
    return {
      token: this.token,
      decoded,
    };
  }
}

export default AuthToken;
