import RouteMeta from './RouteMeta';

export default {
  path: '/',
  name: 'home',
  component: () => import(/* webpackChunkName: "home" */ '@/pages/HomeMenu.vue'),
  props: true,
  meta: new RouteMeta({
    auth: true,
  }),
};
