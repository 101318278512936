import { createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';

const namespace = '/api';

export const Types = {
  ...createApiType(['FETCH_USER_PLAN', 'ADD_PLAN']),
};

export default {
  [Types.FETCH_USER_PLAN]({ dispatch, rootState }, { onSuccess = null, onFailed = null }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_USER_PLAN),
      method: 'get',
      endpoint: `${namespace}/account/${rootState.auth.profile.id}/license`,
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.ADD_PLAN]({ dispatch, rootState }, { licenseKey, onSuccess = null, onFailed = null }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.ADD_PLAN),
      method: 'post',
      endpoint: `${namespace}/account/${rootState.auth.profile.id}/bind-license`,
      data: {
        licenseKey,
      },
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
};
