import auth from './auth';
import contracts from './contracts';
import records from './records';
import payment from './payment';
import plan from './plan';

export default {
  auth,
  contracts,
  records,
  payment,
  plan,
};
