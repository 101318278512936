import { createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';

const namespace = '/api';

export const Types = {
  ...createApiType([
    'FETCH_CONTRACTS',
    'CREATE_CONTRACT',
    'SEND_CONTRACT_TO_OPPOSITE',
    'VERIFY_CONTRACT',
  ]),
};

export default {
  [Types.FETCH_CONTRACTS]({ dispatch, rootState }, { onSuccess = null, onFailed = null }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_CONTRACTS),
      method: 'get',
      endpoint: `${namespace}/account/${rootState.auth.profile.id}/accident-contract`,
      params: {
        isSigned: true,
        page: 0,
        size: 10000,
      },
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.CREATE_CONTRACT](
    { dispatch },
    {
      accidentRecordId,
      compensationAmount,
      compensationType,
      isOemOnly,
      partyList,
      payer,
      paymentType,
      repairShopDecisionSide,
      transferDays,
      onSuccess = null,
      onFailed = null,
    }
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.CREATE_CONTRACT),
      method: 'post',
      endpoint: `${namespace}/accident-contract`,
      params: {},
      data: {
        accidentRecordId,
        compensationAmount,
        compensationType,
        isOemOnly,
        partyList,
        payer,
        paymentType,
        repairShopDecisionSide,
        transferDays,
      },
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.SEND_CONTRACT_TO_OPPOSITE](
    { dispatch },
    { id, mobile, mobileCountryCode, onSuccess = null, onFailed = null }
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.SEND_CONTRACT_TO_OPPOSITE),
      method: 'post',
      endpoint: `${namespace}/accident-contract/${id}/challenge`,
      params: {},
      data: {
        mobile,
        mobileCountryCode,
      },
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.VERIFY_CONTRACT]({ dispatch }, { id, code, onSuccess = null, onFailed = null }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.VERIFY_CONTRACT),
      method: 'post',
      endpoint: `${namespace}/accident-contract/${id}/verify`,
      params: {},
      data: {
        code,
      },
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
};
