import RouteMeta from './RouteMeta';

export default {
  path: '/record',
  component: () => import(/* webpackChunkName: "create" */ '@/pages/Create.vue'),
  props: true,
  meta: new RouteMeta({
    auth: true,
  }),
  children: [
    {
      path: '',
      name: 'record',
      component: () => import(/* webpackChunkName: "record-list" */ '@/pages/EventList.vue'),
      props: true,
      meta: new RouteMeta({
        auth: true,
      }),
    },
    {
      path: 'create',
      name: 'create-record',
      component: () => import(/* webpackChunkName: "create-record" */ '@/components/EventForm.vue'),
      props: true,
      meta: new RouteMeta({
        auth: true,
      }),
    },
    {
      path: ':id/preview',
      name: 'preview-record',
      component: () =>
        import(/* webpackChunkName: "preview-record" */ '@/components/EventView.vue'),
      props: true,
      meta: new RouteMeta({
        auth: true,
      }),
    },
  ],
};
