import { createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';

const namespace = '/api';

export const Types = {
  ...createApiType([
    'FETCH_USER_PAYMENT',
    'FETCH_USER_LICENSE',
    'ADD_USER_PAYMENT',
    'BIND_USER_PAYMENT',
    'BIND_USER_CARD',
    'DELETE_USER_PAYMENT',
    'ORDER_PLAN',
    'CHECK_COUPON',
    'CHECK_COUPON_DISCOUNT',
  ]),
};

export default {
  [Types.FETCH_USER_PAYMENT]({ dispatch, rootState }, { onSuccess = null, onFailed = null }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_USER_PAYMENT),
      method: 'get',
      endpoint: `${namespace}/account/${rootState.auth.profile.id}/card`,
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.FETCH_USER_LICENSE]({ dispatch, rootState }, { onSuccess = null, onFailed = null }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_USER_LICENSE),
      method: 'get',
      endpoint: `${namespace}/account/${rootState.auth.profile.id}/license`,
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.ADD_USER_PAYMENT](
    { dispatch, rootState },
    { cardholderName, prime, onSuccess = null, onFailed = null }
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.ADD_USER_PAYMENT),
      method: 'post',
      endpoint: `${namespace}/account/${rootState.auth.profile.id}/bind-card`,
      data: {
        cardholderName,
        prime,
      },
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.BIND_USER_PAYMENT](
    { dispatch, rootState },
    { cardholderName, prime, discountCode, onSuccess = null, onFailed = null }
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.BIND_USER_PAYMENT),
      method: 'post',
      endpoint: `${namespace}/account/${rootState.auth.profile.id}/bind-card-order`,
      data: {
        cardholderName,
        prime,
        discountCode,
      },
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.BIND_USER_CARD](
    { dispatch, rootState },
    { cardholderName, prime, onSuccess = null, onFailed = null }
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.BIND_USER_CARD),
      method: 'post',
      endpoint: `${namespace}/account/${rootState.auth.profile.id}/bind-card`,
      data: {
        cardholderName,
        prime,
      },
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.DELETE_USER_PAYMENT](
    { dispatch, rootState },
    { cardId, onSuccess = null, onFailed = null }
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.DELETE_USER_PAYMENT),
      method: 'post',
      endpoint: `${namespace}/account/${rootState.auth.profile.id}/remove-card`,
      data: {
        cardId,
      },
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.ORDER_PLAN](
    { dispatch, rootState },
    { cardholderName, discountCode, prime, onSuccess = null, onFailed = null }
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.ORDER_PLAN),
      method: 'post',
      endpoint: `${namespace}/order`,
      data: {
        accountId: rootState.auth.profile.id,
        cardholderName,
        discountCode,
        prime,
      },
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.CHECK_COUPON]({ dispatch }, { code, onSuccess = null, onFailed = null }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.CHECK_COUPON),
      method: 'get',
      endpoint: `${namespace}/discount-code/code/${code}/usable`,
      data: {},
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },

  [Types.CHECK_COUPON_DISCOUNT]({ dispatch }, { code, onSuccess = null, onFailed = null }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.CHECK_COUPON_DISCOUNT),
      method: 'get',
      endpoint: `${namespace}/discount-code?code=${code}`,
      data: {},
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
};
