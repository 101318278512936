import Vue from 'vue';
import VueRouter from 'vue-router';
import { Contract, Home, Insurance, Login, Menu, Payment, Record, User, Plan } from './routes';
import { AuthMiddleware } from './middlewares';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [Contract, Home, Insurance, Login, Menu, Payment, Record, User, Plan],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(AuthMiddleware);

export default router;
