import { createType, createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';

const namespace = '/api';

export const Types = {
  ...createType(['FETCH_CITY_AND_AREA']),
  ...createApiType([
    'FETCH_RECORDS',
    'FETCH_RECORD_BY_ID',
    'FETCH_CITY',
    'FETCH_AREA_BY_CITY',
    'CREATE_RECORD',
    'GET_ADDRESS_BY_LATLNG',
  ]),
};

export default {
  [Types.FETCH_RECORDS]({ dispatch, rootState }, { onSuccess = null, onFailed = null }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_RECORDS),
      method: 'get',
      endpoint: `${namespace}/account/${rootState.auth.profile.id}/my-record`,
      params: {
        hasSignedContract: false,
      },
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.FETCH_RECORD_BY_ID]({ dispatch }, { id, onSuccess = null, onFailed = null }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_RECORD_BY_ID),
      method: 'get',
      endpoint: `${namespace}/accident-record/${id}`,
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.FETCH_CITY]({ dispatch }, { onSuccess = null, onFailed = null }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_CITY),
      method: 'get',
      endpoint: `${namespace}/city`,
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.FETCH_AREA_BY_CITY]({ dispatch }, { cityId, onSuccess = null, onFailed = null }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_AREA_BY_CITY),
      method: 'get',
      endpoint: `${namespace}/city/${cityId}/area`,
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.FETCH_CITY_AND_AREA]({ dispatch }) {
    dispatch(Types.FETCH_CITY, {
      onSuccess: (res) => {
        const { data } = res;
        if (data && data.length) {
          const cityId = data[0].id;
          dispatch('FETCH_AREA_BY_CITY', { cityId });
        }
      },
    });
  },
  [Types.CREATE_RECORD](
    { dispatch },
    {
      address,
      area,
      city,
      locationDescription,
      occuredTime,
      partyList,
      relativeDirectionPhoto1,
      relativeDirectionPhoto2,
      zipCode,
      onSuccess = null,
      onFailed = null,
    }
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.CREATE_RECORD),
      method: 'post',
      endpoint: `${namespace}/accident-record`,
      data: {
        address,
        area,
        city,
        locationDescription,
        occuredTime,
        partyList,
        relativeDirectionPhoto1,
        relativeDirectionPhoto2,
        zipCode,
      },
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.GET_ADDRESS_BY_LATLNG]({ dispatch }, { lat, lng, onSuccess = null, onFailed = null }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.GET_ADDRESS_BY_LATLNG),
      method: 'get',
      endpoint: `${namespace}/google-map`,
      params: {
        lat,
        lng,
      },
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
};
