import { Types } from './actions';

export const initState = {
  city: [],
  area: [],
  records: [],
  currentRecord: null,
  requestingCity: false,
  requestingArea: false,
  requestingRecords: false,
  requestingCurrentRecord: false,
  requestingCreateRecord: false,
  requestingAddress: false,
};

export const mutations = {
  [Types.FETCH_RECORDS_FETCHING](state) {
    state.requestingRecords = true;
  },
  [Types.FETCH_RECORDS_SUCCESS](state, data) {
    state.requestingRecords = false;
    state.records = data.value;
  },
  [Types.FETCH_RECORDS_FAILED](state) {
    state.requestingRecords = false;
  },

  [Types.FETCH_RECORD_BY_ID_FETCHING](state) {
    state.requestingCurrentRecord = true;
  },
  [Types.FETCH_RECORD_BY_ID_SUCCESS](state, data) {
    state.requestingCurrentRecord = false;
    state.currentRecord = data.value;
  },
  [Types.FETCH_RECORD_BY_ID_FAILED](state) {
    state.requestingCurrentRecord = false;
  },

  [Types.FETCH_CITY_FETCHING](state) {
    state.requestingCity = true;
  },
  [Types.FETCH_CITY_SUCCESS](state, data) {
    state.requestingCity = false;
    state.city = data.value.map((r) => ({
      value: r.id,
      text: r.name,
    }));
  },
  [Types.FETCH_CITY_FAILED](state) {
    state.requestingCity = false;
  },

  [Types.FETCH_AREA_BY_CITY_FETCHING](state) {
    state.requestingArea = true;
  },
  [Types.FETCH_AREA_BY_CITY_SUCCESS](state, data) {
    state.requestingArea = false;
    state.area = data.value.map((r) => ({
      value: r.id,
      text: r.name,
      zipCode: r.zipCode,
    }));
  },
  [Types.FETCH_AREA_BY_CITY_FAILED](state) {
    state.requestingArea = false;
  },

  [Types.CREATE_RECORD_FETCHING](state) {
    state.requestingCreateRecord = true;
  },
  [Types.CREATE_RECORD_SUCCESS](state, data) {
    state.requestingCreateRecord = false;
    state.currentRecord = data.value;
  },
  [Types.CREATE_RECORD_FAILED](state) {
    state.requestingCreateRecord = false;
  },

  [Types.GET_ADDRESS_BY_LATLNG_FETCHING](state) {
    state.requestingAddress = true;
  },
  [Types.GET_ADDRESS_BY_LATLNG_SUCCESS](state) {
    state.requestingAddress = false;
  },
  [Types.GET_ADDRESS_BY_LATLNG_FAILED](state) {
    state.requestingAddress = false;
  },
};
