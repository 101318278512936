/* eslint-disable no-unused-vars */
/* eslint-disable no-async-promise-executor */
import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import request from '@/utils/requestHelper';
import modules from '@/store/modules';
import { checkAuth } from '@/utils/auth';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';

const defaultState = {
  identity_options: [
    {
      value: 'DRIVER',
      text: '汽機車駕駛',
    },
    {
      value: 'BIKEDRIVER',
      text: '自行車',
    },
    {
      value: 'PASSENGER',
      text: '乘客',
    },
    {
      value: 'PEDESTRIAN',
      text: '行人',
    },
    {
      value: 'OTHERS',
      text: '其他',
    },
  ],
  compensator_options: [
    {
      value: 'CREATOR',
      text: '我方',
    },
    {
      value: 'OTHERS',
      text: '對方',
    },
    {
      value: null,
      text: '互不賠償',
    },
  ],
  validation_code: '',
  cards: [
    {
      number: '4875-XXXX-XXXX-XXXX',
      type: 'VISA',
      status: '使用中',
    },
    {
      number: '4432-XXXX-XXXX-XXXX',
      type: 'MASTER',
      status: '使用中',
    },
  ],
  card_number: '',
  card_expired_date: '',
  card_security_code: '',
  card_coupon: '',
};

Vue.use(Vuex);

const isProd = process.env.NODE_ENV === 'production';
const plugins = isProd ? [] : [createLogger()];

const store = new Vuex.Store({
  state: defaultState, // TODO: 記得刪掉
  devtools: !isProd,
  strict: true,
  modules,
  plugins,
  actions: {
    [API_REQUEST_ACTION_TYPE](
      { dispatch, commit, state },
      {
        mutationTypes = { requesting: null, success: null, error: null },
        requestKeepingInfo = null,
        headers = {},
        params = {},
        method,
        data = null,
        endpoint,
        auth = null,
      }
    ) {
      return new Promise(async (resolve, reject) => {
        const { requesting, success, error } = mutationTypes;
        if (requesting) {
          commit(requesting, { requestKeepingInfo });
        }
        // eslint-disable-next-line prefer-const
        let requestHeaders = headers;

        if (auth) {
          const isAuthValid = checkAuth(state.auth.token);
          if (isAuthValid) {
            requestHeaders = {
              ...headers,
              Authorization: `Bearer ${state.auth.token}`,
            };
          } else {
            return dispatch('REQUEST_LOGOUT', { isTokenExpired: true });
          }
        }
        try {
          const response = await request({
            headers: requestHeaders,
            method,
            endpoint,
            data,
            params,
          });
          if (success) {
            commit(success, { value: response.data, requestKeepingInfo });
          }
          resolve(response);
        } catch (e) {
          if (error) {
            commit(error, { error: e, requestKeepingInfo });
          }
          reject(e);
        }
        return null;
      });
    },
  },
});

export default store;
