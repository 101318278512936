import RouteMeta from './RouteMeta';

export default {
  path: '/contract',
  component: () => import(/* webpackChunkName: "create" */ '@/pages/Create.vue'),
  props: true,
  meta: new RouteMeta({
    auth: true,
  }),
  children: [
    {
      path: '',
      name: 'contract',
      component: () => import(/* webpackChunkName: "contract-list" */ '@/pages/ContractList.vue'),
      props: true,
      meta: new RouteMeta({
        auth: true,
      }),
    },
    {
      path: 'preview',
      name: 'contract-preview',
      component: () =>
        import(/* webpackChunkName: "preview-contract" */ '@/components/PreviewContract.vue'),
      props: true,
      meta: new RouteMeta({
        auth: true,
      }),
    },
    {
      path: 'create',
      name: 'create-contract',
      component: () =>
        import(/* webpackChunkName: "create-contract" */ '@/components/ContractForm.vue'),
      props: true,
      meta: new RouteMeta({
        auth: true,
      }),
    },
  ],
};
