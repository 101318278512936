import { createType, createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';

const namespace = '/api';

export const Types = {
  ...createType(['LOGOUT']),
  ...createApiType([
    'USER_LOGIN',
    'VERIFY_MOBILE',
    'FETCH_USER_PROFILE',
    'UPDATE_USER',
    'PARTNER_AUTH',
  ]),
};

export default {
  REQUEST_LOGOUT({ commit }, { isTokenExpired }) {
    commit(Types.LOGOUT, { isTokenExpired });
  },
  [Types.USER_LOGIN]({ dispatch }, { mobile, onSuccess = null, onFailed = null }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.USER_LOGIN),
      method: 'post',
      endpoint: `${namespace}/account/login`,
      data: {
        mobile,
      },
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.VERIFY_MOBILE]({ dispatch }, { id, code, onSuccess = null, onFailed = null }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.VERIFY_MOBILE),
      method: 'post',
      endpoint: `${namespace}/account/${id}/verify-mobile`,
      data: {
        code,
      },
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.FETCH_USER_PROFILE](
    { dispatch },
    { onSuccess = null, onFailed = null, goCreateUser, to }
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_USER_PROFILE),
      method: 'get',
      endpoint: `${namespace}/account/me`,
      auth: true,
    })
      .then((response) => {
        if (response.data.logout === true) {
          dispatch('REQUEST_LOGOUT', { isTokenExpired: false });
        }

        if (!response.data.nationalId) {
          if (to.name !== 'create-user') {
            goCreateUser();
          }
        }

        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.UPDATE_USER](
    { dispatch },
    {
      id,
      firstName,
      lastName,
      email,
      birthday,
      nationalId,
      plateNumber,
      address,
      city,
      area,
      zipCode,
      onSuccess = null,
      onFailed = null,
    }
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.UPDATE_USER),
      method: 'post',
      endpoint: `${namespace}/account/${id}/bind-member`,
      data: {
        firstName,
        lastName,
        email,
        birthday,
        nationalId,
        plateNumber,
        address,
        city,
        area,
        zipCode,
      },
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.PARTNER_AUTH]({ dispatch }, { authToken, onSuccess = null, onFailed = null }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.PARTNER_AUTH),
      method: 'post',
      endpoint: `${namespace}/partner/auth`,
      data: {
        authToken,
      },
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
};
