import { Types } from './actions';

export const initState = {
  plan: {},
  requestingPlan: false,
  requestingAddPlan: false,
};

export const mutations = {
  [Types.FETCH_USER_PLAN_FETCHING](state) {
    state.requestingPlan = true;
  },
  [Types.FETCH_USER_PLAN_SUCCESS](state, data) {
    state.requestingPlan = false;
    state.plan = data.value;
  },
  [Types.FETCH_USER_PLAN_FAILED](state) {
    state.requestingPlan = false;
  },

  [Types.ADD_PLAN_FETCHING](state) {
    state.requestingAddPlan = true;
  },
  [Types.ADD_PLAN_SUCCESS](state) {
    state.requestingAddPlan = false;
  },
  [Types.ADD_PLAN_FAILED](state) {
    state.requestingAddPlan = false;
  },
};
