import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import ImageUploadPlugin from 'vue-image-upload-resize';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueMeta from 'vue-meta';
import VConsole from 'vconsole/dist/vconsole.min';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueAnalytics from 'vue-analytics';
import VueGtag from 'vue-gtag';
import Hotjar from './plugins/hotjar';

Vue.use(ImageUploadPlugin);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueAxios, axios);
Vue.use(VueMeta);
Vue.use(VueAnalytics, {
  id: 'G-7BHEYMSJEK',
  router,
});
Vue.use(
  VueGtag,
  {
    config: { id: 'G-7BHEYMSJEK' },
  },
  router
);
Vue.use(Hotjar);

router.afterEach((to) => {
  if (typeof window.hj === 'function') {
    window.hj('stateChange', to.fullPath);
  }
});
// eslint-disable-next-line no-unused-expressions
process.env.VUE_APP_VCONSOLE === 'true' && new VConsole();

new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
});
