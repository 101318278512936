import RouteMeta from './RouteMeta';

export default {
  path: '/payment',
  component: () => import(/* webpackChunkName: "create" */ '@/pages/Create.vue'),
  props: true,
  meta: new RouteMeta({
    auth: true,
  }),
  children: [
    {
      path: '',
      name: 'payment',
      component: () => import(/* webpackChunkName: "payment" */ '@/pages/Pay.vue'),
      props: true,
      meta: new RouteMeta({
        auth: true,
      }),
    },
    {
      path: 'create',
      name: 'create-payment',
      component: () => import(/* webpackChunkName: "create-payment" */ '@/components/CardForm.vue'),
      props: true,
      meta: new RouteMeta({
        auth: true,
      }),
    },
    {
      path: 'order',
      name: 'order-payment',
      component: () => import(/* webpackChunkName: "order-payment" */ '@/components/PayForm.vue'),
      props: true,
      meta: new RouteMeta({
        auth: true,
      }),
    },
  ],
};
