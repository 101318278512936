<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  metaInfo: {
    title: 'PAMO線上車禍律師',
  },
  components: {},
  data() {
    return {};
  },
  mounted() {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();
  },
};
</script>

<style lang="less">
@import '@/assets/lessjs/variables.less';
:root {
  --app-height: 100%;
}
body {
  min-height: -webkit-fill-available;
  background-color: black;
}
.mobileOnly {
  display: none !important;

  @media @mobile {
    display: block !important;
  }
}

.desktopOnly {
  @media @mobile {
    display: none !important;
  }
}

* {
  box-sizing: border-box;
  background-repeat: no-repeat;
  outline: none;
}
#app {
  background-color: black;
  min-height: 100vh;
  color: @light-grey;
}
a {
  color: @highlight !important;
  text-decoration: none !important;
}
img {
  width: 100%;
}

.wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.swiper-pagination-bullet {
  background: #b49d5f !important;
  opacity: 0.3 !important;
}

.swiper-pagination-bullet-active {
  background-color: #8f7633 !important;
  opacity: 1 !important;
}

.clickable {
  cursor: pointer;
}
</style>
