import RouteMeta from './RouteMeta';

export default {
  path: '/plan',
  component: () => import(/* webpackChunkName: "create" */ '@/pages/Create.vue'),
  props: true,
  meta: new RouteMeta({
    auth: true,
  }),
  children: [
    {
      path: '',
      name: 'plan',
      component: () => import(/* webpackChunkName: "plan" */ '@/pages/PlanList.vue'),
      props: true,
      meta: new RouteMeta({
        auth: true,
      }),
    },
    {
      path: 'add',
      name: 'add-plan',
      component: () => import(/* webpackChunkName: "add-user" */ '@/pages/AddPlan.vue'),
      props: true,
      meta: new RouteMeta({
        auth: true,
      }),
    },
  ],
};
